import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckGray } from '../../../assets/icons/icon-check-gray.svg';
import {
    lowercaseRegex,
    uppercaseRegex,
    numberRegex,
    specialCharRegex,
    invalidCharRegex,
} from '../../../shared/utils/passwordValidationUtils';
import styles from './PasswordErrorField.module.scss'

interface PasswordErrorFieldProps {
    password: string;
    onCriteriaChange?: (criteriaStatus: {
        minLength: boolean;
        lowercase: boolean;
        uppercase: boolean;
        number: boolean;
        specialChar: boolean;
        invalidChar: boolean;
    }) => void;
}

const PasswordErrorField: React.FC<PasswordErrorFieldProps> = ({ password, onCriteriaChange }) => {
    const { t } = useTranslation();
    const [minLength, setMinLength] = useState(false);
    const [lowercase, setLowercase] = useState(false);
    const [uppercase, setUppercase] = useState(false);
    const [number, setNumber] = useState(false);
    const [specialChar, setSpecialChar] = useState(false);
    const [invalidChar, setInvalidChar] = useState(false);

    useEffect(() => {
        setMinLength(password.length >= 12);
        setLowercase(lowercaseRegex.test(password));
        setUppercase(uppercaseRegex.test(password));
        setNumber(numberRegex.test(password));
        setSpecialChar(specialCharRegex.test(password));
        setInvalidChar(invalidCharRegex.test(password));
    }, [password]);

    useEffect(() => {
        if (onCriteriaChange) {
            onCriteriaChange({ minLength, lowercase, uppercase, number, specialChar, invalidChar });
        }
    }, [minLength, lowercase, uppercase, number, specialChar, invalidChar, onCriteriaChange]);

    return (
        <div>
            {!minLength && <p className={styles.passwordRule}><CheckGray /> {t('PASSWORD.LENGTH')}</p>}
            {!lowercase && <p className={styles.passwordRule}><CheckGray /> {t('PASSWORD.LOWERCASE')}</p>}
            {!uppercase && <p className={styles.passwordRule}><CheckGray /> {t('PASSWORD.UPPERCASE')}</p>}
            {!number && <p className={styles.passwordRule}><CheckGray /> {t('PASSWORD.NUMBER')}</p>}
            {!specialChar && <p className={styles.special}><CheckGray /><span>{t('PASSWORD.SPECIAL')}</span></p>}
            {invalidChar && <p className={styles.passwordRule} style={{ marginTop: "2.5rem" }}><CheckGray /> {t('PASSWORD.INVALID')}</p>}
        </div>
    );
};

export default PasswordErrorField;
